<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">Dashboard / Add Accounts</h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Name</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Email</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Phone</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Role</p>
            <v-select
              label="Select Role"
              single-line
              outlined
              v-model="role"
              :items="roles"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Password</p>
            <v-text-field
              label="Password"
              single-line
              outlined
              v-model="password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Confirm Password</p>
            <v-text-field
              label="Confirm Password"
              single-line
              outlined
              v-model="confirm_password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            
           <v-btn large block class="primary black--text" @click="createAccount">Submit</v-btn>
          </v-flex>
          
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      role: "",
      roles: ["Super Admin", "Admin", "Flight Coordinator", "Accounts"],
      name: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
      send_mail: true,
    };
  },
  methods: {
    async createAccount() {
      let url = BASE_URL + "/admin";
      let payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        role: this.role,
        password: this.password,
        profileType : "Admin"
      };
      await Axios.post(url, payload);
    },
  },
};
</script>